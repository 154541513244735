import React from 'react'

const ValueCard = ({title, caption}) => {
  return (
    <div className="bg-blue-950 p-6 rounded-0">
        <div className="text-green-500 font-secondary text-base font-semibold">{title}</div>
        <div className="text-sm text-blue-100">{caption}</div>
    </div>
  )
}

export default ValueCard