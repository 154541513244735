import React from 'react'
import { footerMenus } from '../data/menus'
import { address } from '../data/company'
import {MapPin, Mail} from "feather-icons-react"
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="w-full py-20 bg-blue-950 text-white relative overflow-hidden">
        <div className="container w-3/4 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-4">
            
            {
                footerMenus.map((menu, index)=>(
                    <div key={index} className="widget" >
                        <div className="title text-xl mb-3 font-primary">{menu.title}</div>
                        {menu.links.map((link, index)=>(
                            <Link to={link.href} className="transition ease-in duration-400 block -mx-2 p-2 hover:bg-white/10 rounded my-2 text-gray-white/90 text-sm capitalize" alt={link.label}>{link.label}</Link>
                        ))}
                    </div>
                ))
            }
           

            <div className="widget">

            <div className="title text-xl mb-3 font-primary">Reach Us</div>
            <div className="">
                <div className="text-lg font-semibold capitalize flex gap-x-4 items-center">
                    <div className="text-green-600"><MapPin/></div>
                    <div className="col-span-3">
                        <span className="block">{address.location}</span>
                        <span className="block">{`${address.city}, ${address.country}`}</span>                        
                    </div>                    
                </div>
                <div className="text-lg font-semibold capitalize flex gap-x-4 items-center mt-4">
                    <div className="text-green-600"><Mail/></div>
                    <div className="col-span-3">
                        <div className="font-semibold lowercase">{address.email}</div>                  
                    </div>                    
                </div>
                                
                
                </div>           
            </div>
        </div>
        <div className="container w-3/4 mx-auto flex justify-between border-t pt-3 border-t-white/0">
            <div className="text-sm text-blue-100/40">
                &copy; 2024. The Initiates Ug. All Rights Reserved
            </div>
            <div></div>
        </div>
        <div className="absolute w-full bottom-5">
            <div className="font-primary font-bold leading-10 text-green-200/5" style={{fontSize: '18rem', textWrap: 'nowrap'}}>The Initiates UG</div>
        </div>
    </div>
  )
}

export default Footer