import React, { useEffect } from 'react'
import { useService } from '../../Hooks/useService'
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { Paragraph } from '../../components/Paragraph';

const Incineration = () => {
  useEffect(() => {
    document.title = "Incineration - Services - The Initiates Uganda";
  },[]);

  const service = useService('waste incineration');
  return (
    <>
      <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>
          <Paragraph>
          We safely and efficiently incinerate hazardous waste using our 3 tons/hr Bil-chamber Incinerator. Our
          treatment facility eliminates dangerous emissions and has a Continuous Emissions Monitoring System. The
          Incinerator attains over 8000C and 1100oC temperature respectively in the primary and secondary chambers.
          The Secondary chambers have a gas resident time of 2 seconds, and the flue gas is scrubbed and neutralized
          using lime and activated Carbon with an exit temperature of about 100ºC.

          <ul className="list-disc list-outside mt-5  ms-5">
          <li>General Waste</li>
          <li>Agricultural waste</li>
          <li>Liquids, pastes, and sludge</li>
          <li>Chemicals waste</li>
          <li>Infectious and medical waste</li>
          <li>Pharmaceutical WasteRefinery waste</li>

          </ul>
          </Paragraph>

          
        </ServicePage>
      </div>
    </>
  )
}

export default Incineration