import React from 'react'

const ContactCard = ({label, contact,icon}) => {
  return (
    <div className="bg-gray-100 p-6 flex  gap-x-5 items-center">
       { icon &&( <div className="icon text-base text-green-600">{icon}</div>)}
       <div className="contact-info">        
       <div className="text-blue-950 font-secondary text-base font-bold">{label}</div>
        <div className="text-lg text-gray-700 font-bold">{contact}</div>
        </div>
    </div>
  )
}

export default ContactCard