import React, { useEffect } from 'react'
import PageBanner from '../containers/pageBanner'
import background from '../images/build.jpg'
import trash from '../images/trashx.jpg'
import { values, vision, mission } from '../data/company'
import ValueCard from '../components/ValueCard'
// import TeamMember from '../components/TeamMember'

const About = () => {

  useEffect(() => {
    document.title = "About - The Initiates Uganda";
  },[]);
  
  return (
    <>
    <PageBanner background={background} header="About us" caption="we are glad you are here" />
    <div className="w-full pt-10 pb-40 bg-gray-100">
        <div className="container w-3/4 mx-auto">
        <div class="my-6 rounded font-primary text-3xl text-blue-950 mb-5 text-center">Who we are</div>

            <div className="text-center lg:text-2xl lg:leading-10 lg:w-2/3 text-gray-500 mx-auto">
                The Initiates Ug is a professional Waste Management company delivering Waste Management, Industrial Cleaning, and Decontamination services to both Private and Public sectors. The Initiates Ug is a member of The Initiates Group.
            </div>
        </div>
    </div>
    <div class="w-full py-2 -mt-32">
        <div className="container lg:w-3/4 mx-auto flex">
            <div className="lg:w-1/2" style={{backgroundImage: `url(${trash})`, backgroundSize: 'cover'}}></div>
            <div className="bg-blue-950 p-8 lg:w-1/2">
                <div className="grid grid-cols-1 md:grid-cols-4">
                    <div className="font-secondary text-green-600 font-semibold pb-3 lg:pb-0">Vision</div>
                    <div className="text-white col-span-3">
                        {vision}
                    </div>
                </div>
                <div className="my-12 border-b border-b-white/10"></div>
                <div className="grid grid-1 md:grid-cols-4">
                    <div className="font-secondary text-green-600 font-semibold w-80 pb-3 md:pb-0">Mission</div>
                    <div className="text-white col-span-3">
                        {mission.statement}
                        <div className="divider-x my-5"></div>
                        { mission.methods.map((method,index)=>(<div key={index} class="py-1 border-b border-b-white/20">{method}</div>))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="w-full py-10">
        <div class="container lg:w-3/4 mx-auto">
            <div class="block text-center my-6 rounded font-primary text-3xl text-blue-950 mb-5">Core Values</div>
            <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-6 px-10 lg:px-0">
                    { values.map((value, index)=>(
                        <ValueCard key={index} title={value.value} caption={value.description} />
                    )) }
            </div>
        </div>
    </div>
    

    {/* <div className="w-full py-10">
        <div class="container mx-auto w-3/4">            
                <div class="block text-center my-6 rounded font-primary text-3xl text-blue-950 mb-5">Corporate Governance</div>
                <div className="w-full text-justify text-gray-800">
                    We recognizes corporate governance as the bedrock in the attainment of the company’s goals and objectives especially as it relates to sound environmental protection, acceptable corporate social responsibility, and compliance with statutory regulation. The Company continuously thrives in achieving best practices in accordance with the laws and regulations of Uganda.
                </div>
            
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-center gap-6 my-12">
                { management.map((member, index)=>(
                    <TeamMember key={index} name={member.name} position={member.position} image={member.image} />
                ))}
            </div>
        </div>            
    </div> */}

    </>
  )
}

export default About