import React from 'react'

const PageLabel = ({header,caption}) => {
  return (
    <div className="block md:inline-block bg-blue-950 px-12 py-8 rounded-tr-full">
        <h2 className="font-primary text-xl md:text-4xl text-white">{header}</h2>
        <div className="font-secondary md:text-xl text-green-600 mt-1">{caption}</div>
    </div>
  )
}

export default PageLabel