export const footerMenus = [
  {
    title: "Quick Links",
    links: [
      {
        label: "Home",
        href: "/",
      },
      {
        label: "Who we are",
        href: "/about",
      },
      {
        label: "what we do",
        href: "/services",
      },
      {
        label: "Contacts",
        href: "/contacts",
      },
    ],
  },
  {
    title: "Services",
    links: [
      {
        label: "Thermal Desorption",
        href: "/services/thermal_desorption",
      },
      {
        label: "Onsite Drilling Waste Management",
        href: "/services/onsite_drilling_waste_management",
      },
      {
        label: "Incineration",
        href: "/services/incineration",
      },
      {
        label: "Decontamination",
        href: "/services/decontamination",
      },
      {
        label: "Waste Management Facilities",
        href: "/services/waste_management_facilities",
      },
      {
        label: "Waste Water Treament",
        href: "/services/waste_water_treatment",
      },
      {
        label: "",
        href: "",
      },
    ],
  },
  {
    title: "Policies",
    links: [
      {
        label: "Sitemap",
        href: "/sitemap",
      },
      {
        label: "Privacy Policy",
        href: "/privacy_policy",
      },
      {
        label: "Cookie Policy",
        href: "/cookie_policy",
      },
    ],
  },
];
