import React, {useEffect} from 'react'
import { useService } from '../../Hooks/useService';
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { Paragraph } from '../../components/Paragraph';

const ThermalDesorption = () => {
  useEffect(() => {
    document.title = "Thermal Desorption - Services - The Initiates Uganda";
  },[]);

  const service = useService('thermal desorption');    
  
  return (
    <>
      <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>
          <Paragraph>
          Thermal Desorption (TDU) services are thermal processes that uses specialized pieces of equipment known as TDU to vaporize contaminants from a variety of waste materials under controlled temperature and pressure. The contaminated material (waste) is fed into the TDU where it is heated to required temperature for a period of time. This heating process vaporizes the contaminants, leaving the material clean and dry (residual ash). The vaporized contaminants are condensed and collected back as a useful material (recovered oil) while the resultant residue ash is disposed-off as stipulated statutorily. 
</Paragraph>
<Paragraph>
TIU Thermal Desorption Unit (TDU) is a 5 ton per hour capacity Plant that is indirectly fired and recovers hydrocarbon contaminants through a 2‐stage Vapor Recovery System.
</Paragraph>
<Paragraph>
Waste materials that can be treated using our TDU are as follows:<br/><br/>
          <ul className="list-inside list-disc">
          <li>Drilling cuttings Waste (Oil/Synthetic Oil Based Mud – OBM) from drilling Operations</li>
 <li>Oily Sludge – from Crude Oil or Refined Products Tank Cleaning </li>
<li>Oil Contaminated Soil – from oil spill sites.</li>
 <li>Pigging waste from pipeline and slug catcher cleaning</li>
 <li>Refinery Spent Catalyst</li>
          </ul>
          </Paragraph>

        </ServicePage>
      </div>
    </>
  )
}

export default ThermalDesorption