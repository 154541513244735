import React from 'react'
import { Link } from 'react-router-dom'

const ServiceCard = ({name, href, image}) => {
  
  return (
    <Link to={href} style={{backgroundImage: `url(${image})`,  backgroundSize: 'cover'}} className="relative transition flex flex-col justify-end items-baseline ease-in duration-600 group h-52 md:h-72 p-6 bg-gray-200 hover:bg-green-700 hover:-translate-y-1">
       
        <div className="z-10 relative transition-all ease-in duration-200 text-xl leading-6 md:text-2xl font-primary text-white">
            {name}
        </div>   
        <div className="absolute z-0 w-full h-full left-0 top-0 bg-gradient-to-b from-transparent from-60% to-black/70"></div>  

    </Link>
  )
}

export default ServiceCard