import React from 'react'

export const ButtonPrimary = ({ children, className }) => {
  return (
    <button className={`${className} transition-all duration-300 px-5 py-3 bg-blue-950 text-white hover:bg-blue-950/90 hover:-translate-y-1`}>{children}</button>
  )
}

export const ButtonSecondary = ({ children, className }) => {
  return (
    <button className={`${className} transition-all duration-300 px-5 py-3 bg-green-600 text-white/80 hover:bg-green-700 hover:-translate-y-1`}>{children}</button>
  )
}

