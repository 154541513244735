import React, { useEffect } from 'react'
import HomeBanner from '../containers/homeBanner'
import ServiceCard from '../components/ServiceCard'
import { services } from '../data/services'
import { client_logos } from '../data/clients'
import ClientProfile from '../containers/clientProfile'
import ProjectProfile from '../containers/projectProfile'

const Home = () => {
    useEffect(() => {
        document.title = "The Initiates Uganda";
      },[]);
  return (
    <>
        <HomeBanner />
        <div className="w-full bg-blue-950 py-12">
            <div className="container w-3/4 mx-auto">                           
                 <div className="grid grid-cols-1 md:grid-cols-4 justify-center text-white">

                    <div className=" text-2xl md:text-4xl font-primary justify-center py-4 md:py-0 md:px-4"> ISO certififications</div>
                    <div className="border-b md:border-s md:border-b-0 py-4 md:px-8 md:py-0 border-white/15">
                        <div className="font-bold text-lg mb-1">9001:2015</div>
                        <div className="text-white/50 text-sm">Quality Management Systems</div>
                    </div>
                    <div className="border-b md:border-s md:border-b-0 py-4 md:px-8 md:py-0 border-white/15">
                        <div className="font-bold text-lg mb-1">14001:2015</div>
                        <div className="text-white/50 text-sm">Environmental Management Systems</div>
                    </div>
                    <div className="border-b md:border-s md:border-b-0 py-4 md:px-8 md:py-0 border-white/15">
                        <div className="font-bold text-lg mb-1">45001:2018</div>
                        <div className="text-white/50 text-sm">Occupational Health & Safety Management Systems</div>
                    </div>
                 </div>
            </div>            
        </div>

        <div className="w-full py-10">
                <div className="container mx-auto grid grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 rounded px-2 md:px-0">
                    { services.map((service, index)=>(
                    <ServiceCard
                        key={index}
                        name={service.name}
                        caption={service.description}
                        href={service.href}
                        image={service.image}
                    />
                        ))
                    }
                  
                </div>            
        </div>
        <div className="container mx-auto pb-10">
            <ClientProfile />
        </div>
        <div className="container mx-auto py-10 md:flex md:flex-wrap md:space-x-14 hidden">
{ client_logos.map((client, index) =>(
    <img src={client.image} alt={client.name} style={{height: "50px", objectFit:"cover", mixBlendMode:"color-dodge"}} />
))}
        </div>
        <div className="container mx-auto pb-10">
            <ProjectProfile />
        </div>
        </>
  )
}

export default Home