import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import React, { Fragment,useState } from 'react'
import { Link } from 'react-router-dom'
import { MapPin, Linkedin, Menu, X, ChevronDown } from 'feather-icons-react'
import logo from '../../src/logo-transparent.png'
import { services } from '../data/services'
import TopMenuItem from '../components/TopMenuItem'


const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const classNames= (...classes) =>{
        return classes.filter(Boolean).join(' ');
    }
 
  return (
    <header className="bg-white relative z-20">
        <nav className="container px-5 lg:px-0 lg:w-3/4 bg-white flex mx-auto items-center justify-between py-5" aria-label="Global">
            <div className="logo flex items-center bg-transparent">
                <Link to='/' className="inline-block bg-transparent" style={{mixBlendMode:'multiply'}} >
                    <img src={logo} alt="TIU" aria-label="TIU" className="h-10 lg:h-16 w-auto" style={{mixBlendMode:'darken'}}  />
                </Link>
            </div>
            <div className="flex lg:hidden">
                <button type='button' className='-m-2 inline-flex items-center justify-center p-2.5 text-gray-800' 
                onClick={()=>setMobileMenuOpen(!mobileMenuOpen)}>
                    <span className="sr-only">Open main menu</span>
                    {mobileMenuOpen ? null : <Menu aria-hidden="true" className="h-6 w-6"/> }
                </button>
            </div>
            <Popover.Group className="hidden lg:flex lg:gap-x-6">
                <TopMenuItem link="/">Home</TopMenuItem>
                <TopMenuItem link="/about">Who we are</TopMenuItem>                
                    <Popover className="relative">
                        <Popover.Button>
                            <TopMenuItem as="div"> What we do</TopMenuItem>                           
                        </Popover.Button>
                        <Transition 
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-out duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden bg-white shadow-md ring-1 ring-gray-700/5">
                              <div className="transition-all duration-500 ease-in p-4">
                                { services.map((service, index) => (
                                    <Link to={service.href} key={index} className="group flex items-center relative gap-x-6 p-2 leading-6 hover:bg-blue-950/10 transition-all duration-200 ease-in" >
                                        <div className="flex-auto">
                                            <div className="block font-normal text-sm text-gray-800">{service.name}</div>
                                        </div>                                        
                                    </Link>
                                ))}
                              </div>
                            </Popover.Panel>

                        </Transition>
                    </Popover>                
                <TopMenuItem link="/contacts">Contacts</TopMenuItem>
                </Popover.Group>
            
            <div className="hidden icons lg:flex lg:justify-end lg:items-center lg:gap-x-4">
                <a href="https://facebook.com" className="transition duration-300 ease-in  p-2 text-sm flex items-center hover:bg-blue-950/20"><MapPin className="text-black text-sm"/></a>  
                <a href="https://facebook.com" className="transition duration-300 ease-in  p-2 text-sm flex items-center hover:bg-blue-950/20"><Linkedin className="text-sm text-black"/></a>                  
            </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10">
                <Dialog.Panel className="top-0 z-30 fixed inset-y-0 right-0 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <>&nbsp;</>
                        <button className="-m-2.5 text-gray-600 p-2.5" onClick={()=>setMobileMenuOpen(false)}>
                            <span className="sr-only">Close Menu</span>
                            <X className="h-6 w-6" aria-hidden="true"/>
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="space-y-6 py-6">
                            <Link to='/' className="-mx-3 block px-3 py-2 text-2xl font-normal font-primary leading-7 text-gray-900 hover:bg-gray-50">Home</Link>
                            <Link to='/about' className="-mx-3 block px-3 py-2 text-2xl font-normal font-primary leading-7 text-gray-900 hover:bg-gray-50">About</Link>
                            <Disclosure as="div" className="-mx-3">
                                {({open}) => (
                                    <>
                                    <Disclosure.Button className="w-full flex justify-between items-center text-2xl font-primary leading-7 pr-3.5 py-2 pl-3 text-gray-900 hover:bg-gray-50">
                                        Services
                                        <ChevronDown className={classNames(open ? 'rotate-180':'', 'h-5 w-5 flex-none')} aria-hidden="true" />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="mt-2 space-y-2">
                                        {
                                            services.map((service, index)=>(
                                                <Disclosure.Button key={index} as="a" href={service.href} className="pl-6 pr-3 font-normal block leading-7 text-xl text-gray-800 py-2 font-primary hover:bg-gray-50">
                                                    {service.name}
                                                </Disclosure.Button>
                                            ))
                                        }

                                    </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Link to='/contacts' className="-mx-3 block px-3 py-2 text-2xl font-normal font-primary leading-7 text-gray-900 hover:bg-gray-50">Contacts</Link>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    </header>
  )
}

export default Header