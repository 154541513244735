import React, { useEffect } from 'react'
import PageBanner from '../../containers/pageBanner'
import background from '../../images/build.jpg'
import { services } from '../../data/services'
import ServiceCard from '../../components/ServiceCard'

const Services = () => {
  useEffect(() => {
    document.title = "Services - The Initiates Uganda";
  },[]);
  return (
    <>
      <PageBanner background={background} header="Services" caption="Explore our services" />
      <div className="w-full py-20">
        <div className="container w-3/4 mx-auto">
          <div className="grid grid-cols-3 gap-2">
          {services.map((service,index)=>(
            <ServiceCard key={index} name={service.name} caption={service.description} href={service.href} image={service.image}/>
          ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default Services