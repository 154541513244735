import React,{useState} from 'react'
import { projects }  from '../data/projects'
import {MapPin} from 'feather-icons-react'

const ProjectProfile = () => {
   const [project, setProject] = useState(projects[0])

   const handleNext = () => {
    const currentIndex = projects.indexOf(project);
    const nextIndex = (currentIndex + 1) % projects.length;
    setProject(projects[nextIndex]);
   }

   const handlePrevious = () => {
    const currentIndex = projects.indexOf(project);
    const previousIndex = (currentIndex - 1 + projects.length) % projects.length;
    setProject(projects[previousIndex]);
   }
  return (
    <div className="relative">
        <div className="flex justify-between items-center">
                <div class="inline-block my-6 rounded font-primary text-3xl text-blue-950 mb-5"></div>
                {projects.length > 1 && (<div className="profile-nav flex items-center justify-end gap-3">
                        <button className="px-5 py-2 bg-blue-950/10 hover:bg-blue-950/20 text-blue-950 font-semibold" onClick={handlePrevious}>Previous</button>
                        <button className="px-5 py-2 bg-blue-950/10 hover:bg-blue-950/20 text-blue-950 font-semibold" onClick={handleNext}>Next</button>
                </div>)}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
            <div className="client-description px-6 lg:px-16 py-10">
                <div className="flex justify-between items-center">
                    <h4 className="text-4xl font-normal font-primary my-3 text-blue-95">{project.name}</h4>                    
                </div>
                <div className="flex gap-x-6"><MapPin className="text-green-600" /> {project.location}</div>
                {/* <div className="flex gap-x-6 mt-2"><Users className="text-green-600" /> {project.partners.map((partner, index)=>(
                    <span key={index}>{`${partner.name}`}</span>
                ))}</div> */}

                <div className="text-sm text-justify text-gray-500/90 leading-6 mt-8">
                    {project.summary}
                </div>                    
            </div>

            <div className="client-image px-4 md:py-0">
                <img src={project.image} alt='' />
            </div>                
                
        </div>
        <div className="absolute text-black/5 -top-5 font-primary left-0" style={{fontSize: '8rem'}}>Projects</div>
     </div>
  )
}

export default ProjectProfile