import React, { useEffect } from 'react'
import { useService } from '../../Hooks/useService'
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { Paragraph } from '../../components/Paragraph';

const WBM = () => {
  useEffect(() => {
    document.title = "Consultancy - Services - The Initiates Uganda";
  },[]);

    const service = useService('waste based mud(wbm) and hazardous liquid treatment and disposal');
  return (
    <>
         <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>
            <Paragraph>
            Water-based mud is a type of drilling mud consisting mainly of water, bentonite, and other
 additives to modify it and make its usage more effective. While the water component of WBM
 is non-hazardous, some of the numerous chemical additives it is composed of are toxic and
 harmful to the environment and its flora/fauna. Hence the regulation for its treatment before
 disposal.</Paragraph>

 <Paragraph>
 WBM generated during drilling operation could be in solid or semi-solid/liquid form. TIU
 treatment of the solid form involves blending with cement and other additives to facilitate
 stabilization/fixation of the waste. The stabilized waste could be disposed-off in a landfill. 
The technology for management of the semi-liquid or spent WBM includes, Coagulation,
 Flocculation, Stabilization/Fixation of the “flocculated” mass. </Paragraph>

 <Paragraph>
The liquid phase – wastewater – recovered from our WBM Treatment plant is passed through
 our Wastewater Treatment Plant for further treatment before disposal of the clean water.
 Reverse Osmosis (RO) Unit is incorporated in the Wastewater Treatment Unit to further help in
 purification of the wastewater before discharge. 
            </Paragraph>
           
        </ServicePage>
        </div>
    </>
  )
}

export default WBM