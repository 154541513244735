export const values = [
    {
        value: 'Professionalism',
        description: 'Integrity, Expertise and Standard'
    },
    {
        value: 'Innovation',
        description: 'A solution that is simple and efficient'
    },
    {
        value: 'Commitment',
        description: 'Commitment to safety, environmental protection and compliance to law'
    }
]

export const whyChooseUs = [
    {
        reason: 'Proven Trackrecord',
        description: '100+ projects completed effectively'
    },
    {
        reason: 'Experienced Experts',
        description: 'We\'ve built a team of highly skilled and experienced personnel'
    },
    {
        reason: 'Professional Delivery',
        description: 'We put ethics and integrity at the forefront'
    }
]



export const address = {
    location: "Plot 63 Naguru Dr.",
    city: "Kampala",
    country: "Uganda",
    phone: ["+25675993318","+256773991990"],
    email: "info@theinitiatesug.com",
    mapPin:"https://maps.google.com",
    linkedin: "https://linkedin.com",
    mailto: "mailto:info@theinitiatesug.com",
    embededMap:`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.747692667678!2d32.59880597532874!3d0.3409173996557452!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dbbb7111aaaab%3A0x2bc02fa45b660853!2s63%20Naguru%20Dr%2C%20Kampala!5e0!3m2!1sen!2sug!4v1706282882674!5m2!1sen!2sug" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
}

export const vision = "To facilitate Industry and Man co-existence in a safe Environment.";
export const mission = {
    statement: "To be the industry’s leading provider of Waste Management and Industrial Cleaning services in Uganda that eliminate clients’ exposure and protect the environment through:",
    methods: [
        "Adherence to best practices",
        "Consildation of experience",
        "Improvement aimed at adding value to our clients' business and public life"
    ]
}

export const management = [
    {
        name: 'Chris Kaweesa',
        position: 'Managing Director',
        image:'/images/team/chris-kaweesa.jpg'
    },
    {
        name: 'Chris Ogidi',
        position: 'Executive Director',
        image: '/images/team/chris-ogidi.jpg'
    },
    {
        name: 'Joseph Muganga',
        position: 'Finance & HR Manager',
        image: '/images/egypt.jpg'
    },
    {
        name: 'Temitope Odeshi Adewale',
        position: 'Waste Management Manager',
        image: '/images/team/temitope.jpg'
    }
]