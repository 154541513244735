import React, { useEffect } from 'react'
import PageBanner from '../../containers/pageBanner'
import ServicePage from '../../containers/servicePage'
import { Paragraph } from '../../components/Paragraph'
import { useService } from '../../Hooks/useService'

const IndustrialCleaning = () => {
  useEffect(() => {
    document.title = "Decomposition - Services - The Initiates Uganda";
  },[]);

  const service = useService('industrial cleaning'); 

  return (
    <>
      <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>          
          <Paragraph>
          Industrial cleaning involves use of the specialized personnel, methods, specialist equipment
 and machinery to achieve a deep, thorough cleaning of potentially hazardous areas of a
 facility. This process maintains the cleanliness and hygiene of an area, helping to ensure it
 meets the appropriate health and safety regulations, as well as providing pleasant
 conditions for employees to work in.
 </Paragraph>

 <Paragraph> Industrial cleaning services is an essential tool for maintaining equipment or facility. The
 process helps to remove heavy deposits of dirt, grime and other materials that can
 accumulate on industrial equipment and thus affect operations or its usage negatively.
 Some industrial cleaning services that TIU undertakes include:
<ul className="my-3 list-disc list-inside">
<li>Crude Oil and Refined Products Storage Tanks </li>
<li>Pipelines, Drainages and Sewer Cleaning</li>
 <li>Heat Exchanger Tubes and Fin Cleaning</li>
          </ul>
</Paragraph>
          



          

        </ServicePage>
      </div>
    </>
  )
}

export default IndustrialCleaning;