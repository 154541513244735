export const projects = [
  {
    name: "Albertine Waste Management JV",
    location: "Albertine Region",
    partners: [
      {
        name: "White Nile Waste Management",
        logo: "/images/partners/logo.png",
      },
      {
        name: "Epsilon",
        logo: "/images/partners/logo.png",
      },
    ],
    image: "/images/projects/albertine/albertine-project-plant.jpg",
    summary: `The Albertine Waste Management project stands at the forefront of sustainable development in the vibrant heart of 
       Uganda's Albertine Region. In collaboration with White Nile Consult, Epsilon Limited, and The Initiates Uganda, this 
       joint venture integrates the expertise of three industry leaders.White Nile Waste Consult brings its proficiency in solid 
       waste management, Epsilon limited contributes its logistical prowess in transportation, while The Initiates Uganda specializes 
       in the intricate domain of liquid waste management. Together, these pillars of innovation converge to create a holistic 
       and environmentally conscious solution, poised to set new standards for waste management in the burgeoning landscape of 
       the Albertine Region. This venture not only exemplifies a commitment to sustainability but also underscores the collective 
       dedication to forging a cleaner, more resilient future for the region and beyond.`,
  },
];
