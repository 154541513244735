import React from 'react'
import Header from '../containers/header'
import Footer from '../containers/footer'

const Wrapper = ({children}) => {
  return (
    <>
        <Header/>
        {children}
        <Footer/>
    </>
  )
}

export default Wrapper