export const clients = [
  {
    name: "Total Energies",
    image: "/images/clients/total-energies.jpg",
    logo: "/images/clients/logos/total_energies.png",
    summary:
      "Elevating the standard of environmental stewardship in the dynamic landscape of Uganda, Total Energies emerges as a distinguished multinational oil and gas corporation boasting an extensive network of over 200 sites across the country. In a strategic alliance that underscores a commitment to sustainability, The Initiates Uganda has been entrusted with the pivotal responsibility of waste management at five of these strategic sites. This partnership extends its reach to prominent locations such as Bulisa, Tangi, DSB, Gunya 1, and Ogiri, where meticulous waste management practices are implemented with precision and expertise",
    sites: [
      {
        name: "Tangi",
        image: "",
      },
      {
        name: "Bulisa",
        image: "",
      },
      {
        name: "DSB",
        image: "",
      },
      {
        name: "Gunya",
        image: "",
      },
      {
        name: "Ogiri",
        image: "",
      },
    ],
  },
];

export const client_logos = [
  {
    name: "Total Energies",
    image: "/images/clients/logos/total_energies.png",
  },
  {
    name: "Sinopec",
    image: "/images/clients/logos/sinopec.png",
  },
  {
    name: "McDermott",
    image: "/images/clients/logos/mcdermott.png",
  },
  {
    name: "Albertine Waste",
    image: "/images/clients/logos/albertine_waste.png",
  },
  {
    name: "Fabrication Systems",
    image: "/images/clients/logos/fabrication_systems.png",
  },
  {
    name: "Pearl Engineering",
    image: "/images/clients/logos/pearl_engineering.png",
  },
];
