import React from 'react'
import PageLabel from '../components/PageLabel'

const PageBanner = ({background,header,caption}) => {
  return (
    <div className="w-full md:pt-40 md:pb-40 bg-white relative z-0" 
    style={{backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center center'}}>
        <div className="container md:w-3/4 mx-auto flex h-full items-center justify-start">
            <div className="md:w-2/4">
                <PageLabel header={header} caption={caption} />
           </div>         
        </div>
    </div>
  )
}

export default PageBanner