import React, { useEffect } from 'react'
import { useService } from '../../Hooks/useService';
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { Subheader } from '../../components/Subheader';
import { Paragraph } from '../../components/Paragraph';

const OnsiteDrillingWasteManagement = () => {
  useEffect(() => {
    document.title = " Onsite Drilling Waste Management - Services - The Initiates Uganda";
  },[]);

  const service = useService('onsite drilling waste management');
  return (
    <>
      <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>
          <Subheader>
            Vacuum Transfer System
          </Subheader>
          <Paragraph>
          Our VTS is entirely a pneumatic system that is intrinsically safe for operation in any environment. It is
          designed to transfer a wide array of materials from solid to liquid from a depth of above 40metres to surface,
          and can discharge/deliver in excess of 800 meters away whilst remaining fully automatic in its operation. The
          unit is compact and fully enclosed to achieve zero dischargeThe System can be used for; sludge evacuation
          and cleaning of FPSO, Crude tanks, Thickener de-sludge, Petrochemical tank cleaning, heavy crude transfer,
          raw effluent pond de-sludging, and Hazardous waste recovery.
          </Paragraph>

          <Subheader>
            Sludge Management System (SMS)
          </Subheader>
          <Paragraph>
          The SMS is a portable unit with minimal footprint and is driven by compressed air making it suitable for work
          in a hazardous environment. The system is capable of dewatering 30,000 liters of sludge per hour. The SMS
          unit is developed to address two significant issues. The first is to reduce the amount of liquid in sludge onsite thereby reducing the cost of handling. The second is to deliver a cost-effective system that reduces the
          amount of sludge that is sent for off-site treatment/disposal.

          </Paragraph>
          
        </ServicePage>
      </div>
    </>
  )
}

export default OnsiteDrillingWasteManagement