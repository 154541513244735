import { Thermometer, Filter, Bell, Activity } from "feather-icons-react";

export const services = [
  {
    name: "Thermal Desorption",
    href: "/services/thermal_desorption",
    icon: <Thermometer />,
    image: "/images/services/thermal_desorption.jpg",
  },

  {
    name: "Waste Incineration",
    href: "/services/incineration",
    icon: <Filter />,
    image: "/images/services/incineration.jpg",
  },
  {
    name: "Industrial Cleaning",
    href: "/services/industrial_cleaning",
    icon: <Bell />,
    image: "/images/services/industrial_cleaning.jpg",
  },
  {
    name: "Waste Based Mud(WBM) and Hazardous Liquid Treatment and Disposal",
    href: "/services/wbm_and_hazardous_liquid_treatment_and_disposal",
    icon: <Activity />,
    image: "/images/services/wbm.png",
  },
  {
    name: "Sewage Treatment",
    href: "/services/waste_water_treatment",
    icon: <Activity />,
    image: "/images/services/waste_water_treatment.jpg",
  },
  {
    name: "Electronic Waste Recovery and Recycling",
    href: "/services/ewaste_recovery_and_recycling",
    icon: <Filter />,
    image: "/images/services/e_waste.png",
  },
];
