import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className="flex-col w-full py-40 item-center justify-center bg-gray-100 text-center">
        <div className="font-light text-9xl">404</div>
        <div className="text-gray-500 my-5 font-bold text-4xl ">Oops!</div>
        <div className="text-blck my-5 text-sm">The page you are looking for may have changed or does not exist</div>
        <Link to="/" className="inline-block px-6 py-2 bg-green-600 text-white/80">Home</Link>
    </div>
  )
}

export default PageNotFound