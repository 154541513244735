
import { Link } from 'react-router-dom'
const TopMenuItem = ({children, link, as='link'}) => {

    const classes = "transition duration-300 ease-in text-lg block font-primary leading-6 text-black px-3 py-2.5 hover:bg-blue-950/10 hover:text-green-700"

    if(as === "link"){ 
    return (
    <Link to={link} className={classes}>
        {children}
    </Link>
  )} else if(as === "div"){
    return <div className={classes}>
        {children}
    </div>
  } else if(as === 'a'){
    return <a href={link} alt='' className={classes} >{children}</a>
  } else {
    return null;
  }
}

export default TopMenuItem