import React, { useEffect} from 'react'
import { useService } from '../../Hooks/useService';
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { Paragraph } from '../../components/Paragraph';

const Ewaste = () => {
    const service = useService('electronic waste recovery and recycling');

    useEffect(() => {
        document.title = `${service.name}  - Services - The Initiates Uganda`;
      },[service]);

    
    return (
    <>
        <PageBanner header={service.name} caption={service.description} background={service.image} />
        <div className="w-full py-20">
          <ServicePage>
            <Paragraph>
            E-waste can be described as any Electrical & Electronics Equipment (EEE) that has reached its
 end of life and ready to be discarded. TIU intends to operate a state-of-the-art e-waste
 processing facility which will process most types of e-waste with adherence to high HSE
 standards and in compliance with relevant government regulations. We shall process:
                needs.
            </Paragraph>
            
            <Paragraph>
                Our Municipal Solid Waste Management Services include:
                <ul className="list-disc list-inside">
                <li>Large & Small Household Appliances (LSHA: ovens, refrigerators, and toasters, vacuum)</li>
 <li>Office and Communication Devices (OCD: computers, printers, scanners,  faxes etc.)</li>
 <li>Entertainment Electronics Devices (EED: TVs, Hifis, and portable CD Players etc.)</li>
 <li>Data Destruction Service:</li>
 <li>E-Waste Dismantling and Segregation</li>
                </ul>
            </Paragraph>
           
            
          </ServicePage>
          </div>
    </>)
}

export default Ewaste