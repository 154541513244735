export const slides = [
  {
    background: "/images/slides/tiu_slide_1.jpg",
  },
  {
    background: "/images/slides/tiu_slide_2.jpg",
  },
  {
    background: "/images/slides/tiu_slide_3.jpg",
  },
  {
    background: "/images/slides/tiu_slide_4.jpg",
  },
  // {
  //   background: "/images/slides/tiu_slide_6.jpg",
  // },
  {
    background: "/images/slides/tiu_slide_7.jpg",
  },
];
