import React from 'react'
import { services } from '../data/services'
import{ Link } from 'react-router-dom'
import { Subheader } from '../components/Subheader'
import { ButtonPrimary } from '../components/Buttons'

const ServicePage = ({children}) => {
  return (
    <div className="container md:w-3/4 mx-auto grid grid-cols-1 lg:grid-cols-3">
        <div className="content px-5 md:px-12 leading-6 lg:col-span-2 text-justify text-gray-500 text-sm">
            {children}
        </div>
        <div className="ad-section border-l border-l-gray-200 px-5 hidden lg:block">
            <div className="mb-5 bg-green-600 text-white p-8">
              <div className="text-2xl font-primary mb-6">Ask about our services.</div>
              <p className="text-sm">If you'd like more or specific information about our services please get in touch</p>
              <div className="mt-6">
                <Link to='/contacts'><ButtonPrimary className="w-full">Contact us</ButtonPrimary></Link>
              </div>
            </div>
            <Subheader>Our Services</Subheader>
            {services.map((service, index)=>(
                <Link key={index} to={service.href} className="transition-all ease-in duration-200 block border-b border-b-gray-100 py-3 text-xs text-gray-500 hover:bg-gray-100 hover:px-2 hover:font-semibold">{service.name}</Link>
            ))}
        </div>
    </div>
  )
}

export default ServicePage