import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Wrapper from "./components/Wrapper";
import ThermalDesorption from "./pages/Services/ThermalDesorption";
import OnsiteDrillingWasteManagement from "./pages/Services/OnsiteDrillingWasteManagement";
import Incineration from "./pages/Services/Incineration";
import WasteManagementFacilities from "./pages/Services/WasteManagementFacilities";
import WasteWateTreatment from "./pages/Services/WasteWateTreatment";
import Contacts from "./pages/Contacts";
import PageNotFound from "./pages/error/404";
import ScrollToTop from "./components/ScrollToTop";
import IndustrialCleaning from "./pages/Services/IndustrialCleaning";
import WBM from "./pages/Services/WBM";
import Ewaste from "./pages/Services/Ewaste";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services">
            <Route index element={<Services />} />
            <Route
              path="/services/thermal_desorption"
              element={<ThermalDesorption />}
            />
            <Route
              path="/services/onsite_drilling_waste_management"
              element={<OnsiteDrillingWasteManagement />}
            />
            <Route path="/services/incineration" element={<Incineration />} />
            <Route
              path="/services/industrial_cleaning"
              element={<IndustrialCleaning />}
            />
            <Route
              path="/services/waste_management_facilities"
              element={<WasteManagementFacilities />}
            />
            <Route
              path="/services/waste_water_treatment"
              element={<WasteWateTreatment />}
            />
            <Route path="/services/wbm_and_hazardous_liquid_treatment_and_disposal" element={<WBM/>} />

            <Route
              path="/services/ewaste_recovery_and_recycling"
              element={<Ewaste/>}
            />
          </Route>
          <Route path="/contacts" element={<Contacts />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
