import React, {useEffect} from 'react'
import PageBanner from '../containers/pageBanner'
import background from '../images/contactus.jpg'
import { address } from '../data/company'
import ContactCard from '../components/ContactCard'
import { MapPin, Mail, Phone, Linkedin} from 'feather-icons-react'


const Contacts = () => {
  useEffect(() => {
    document.title = "Contacts - The Initiates Uganda";
  },[]);

  return (
    <>
    <PageBanner background={background} header="Contact us" caption="We are here to serve" />
    <div className="w-full pt-20">
      <div className="container mx-auto md:w-3/4 grid grid-cols-1 md:grid-cols-2 items-center gap-4 md:gap-6 px-5 md:px-0">
          <ContactCard label="Email" contact={address.email}  icon={<Mail/>}/>
          <ContactCard label="Call" contact={address.phone[0]} icon={<Phone/>}/>
      </div>
    </div>
    
    <div className="w-full py-20">
      <div className="container md:w-3/4 mx-auto flex flex-col md:flex-row px-5 md:px-0">
      <div className="bg-green-600 flex flex-row md:flex-col gap-6 items-center justify-center text-white/70 px-5 py-4">
        <div><a href={address.linkedin} alt="linkedin" target='_blank' rel='noreferrer'><Linkedin/></a></div>
        <div><a href={address.mailto} alt="linkedin"><Mail/></a></div>
      </div>  

      <div className="bg-blue-950 text-base text-white flex flex-col justify-center px-8 py-10 md:py-0 gap-x-5 z-10 relative">
         
          <div className="text-sm mb-5 text-white/70 text-center">
            Visit us at our office.
          </div>

         <div className=" flex flex-col items-center gap-x-3"> 
          <div className="text-white/70"><MapPin/></div>
          <div className="font-bold">
            <div>{address.location}</div> 
            <div>{`${address.city}, ${address.country}`}</div> 
          </div>
          </div>
      </div>
      
        <div className="flex-1 p-3 bg-gray-100" dangerouslySetInnerHTML={{__html: address.embededMap }} />        
      </div>
    </div>
    </>
  )
}

export default Contacts