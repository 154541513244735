import React, {useEffect, useState} from 'react'
import { slides } from '../data/general'
const HomeBanner = () => {

const [currentSlide, setCurrentSlide] = useState(0); 
const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    console.log(currentSlide);
}

const getSlide = (index) =>{
    setCurrentSlide(index);
}

useEffect(()=>{
  const interval = setInterval(()=>{
    nextSlide();
  },15000);

  return () =>{
    clearInterval(interval);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

  return (
    <div className='slider-wraper w-full relative h-96 z-10 sm-mt-0' style={{minHeight: '580px'}}>
        {
            slides.map((slide, index)=>(
                <div key={index} className={`transition-all duration-500 ease-in-out absolute w-full top-0 left-0 bottom-0 bg-gray-100 z-20 ${index === currentSlide ? "opacity-100":"opacity-0"}`} style={{backgroundImage: `url(${slide.background})`, backgroundSize: 'cover', backgroundPosition:'center center'}} />
            ))
        }
        <div className="absolute w-full top-0 bottom-0 z-30 bg-blue-950/0"></div>
        <div className="w-full absolute z-40 pb-32 top-0 bottom-0">
            <div className="container px-5 lg:px-0 lg:w-3/4 mx-auto flex h-full items-end justify-start">
                <div className="w-full lg:w-2/4">
                    <h4 className="text-4xl text-white lg:text-3xl font-primary inline-block p-4 px-2 bg-black/50">We Provide Waste Management Solutions</h4>
                    <div className="font-secondary lg:text-xl leading-6 p-2 mt-1 font-normal text-white/80 bg-black/80">
                    Supporting greener production & consumption.
                    </div>
                    {/* <div className="gap-x-5 flex items-center font-w500">
                       { slides[currentSlide].link && (<Link to={slides[currentSlide].link}><ButtonPrimary className="px-8">{slides[currentSlide].linkLabel}</ButtonPrimary></Link>)}
                    </div> */}
                </div>
            </div>
        </div>  
        <div className="absolute w-full text-center bottom-6 z-50">
            {slides.map((_,index) => (
                <button className={`w-6 h-6 rounded-full mx-1 ${index === currentSlide ? "bg-white":"bg-white/60"}`} onClick={() => getSlide(index)}/>
            ))}
        </div> 
    </div>
  )
}

export default HomeBanner