import React,{ useEffect } from 'react'
import { useService } from '../../Hooks/useService';
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { Paragraph } from '../../components/Paragraph';
import { Subheader } from '../../components/Subheader';

const WasteManagementFacilities = () => {
  useEffect(() => {
    document.title = "Waste Management Facilities - Services - The Initiates Uganda";
  },[]);
  const service = useService('waste management facilities');
  return (
    <>
      <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>
          <Paragraph>
          We work closely with clients to develop Waste Management Facility projects from conception to
          commissioning. We also operate and manage Facilities for clients. Our expertise in the management of
          Waste facilities include:
          </Paragraph>
          <Subheader>Material Recovery Facility</Subheader>
          <Paragraph>
          We have the capacity to provide clients with highly efficient material recovery facilities. Our systems
          assure clients of high throughput, and recovery rates paired with low maintenance based on quality design
          and construction. The system also ensures the quality of recyclables as they do not deteriorate
          throughout the processes of recovery.
          </Paragraph>
          <Subheader>Waste Water Facilities</Subheader>
          <Paragraph>
          We have capacity to deliver innovative solutions to our clients in wastewater treatment. We offer quality
          construction and operation services in waste water treatment facility.
          </Paragraph>
          <Subheader>Incineration Unit</Subheader>
          <Paragraph>
          We operate incineration plants for incinerating all kinds of hazardous waste. Our design meets
          international standards and can be trusted in all kind of environment.
          </Paragraph>
          
        </ServicePage>
      </div>
    </>
  )
}

export default WasteManagementFacilities