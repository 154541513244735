import React, { useEffect} from 'react'
import PageBanner from '../../containers/pageBanner';
import ServicePage from '../../containers/servicePage';
import { useService } from '../../Hooks/useService';
import { Paragraph } from '../../components/Paragraph';
import { Subheader } from '../../components/Subheader';

const WasteWateTreatment = () => {
  useEffect(() => {
    document.title = "Waste Water Treatment - Services - The Initiates Uganda";
  },[]);

  const service = useService('sewage treatment');
  return (
    <>
      <PageBanner header={service.name} caption={service.description} background={service.image} />
      <div className="w-full py-20">
        <ServicePage>
          <Paragraph>
          The technology involved in treating this waste type is a new technology called Moving Bed Bio reactor (MBBR). Sewage types treated include:
          </Paragraph>
          <Paragraph>
          <ul className="list-disc list-outside ms-4 mt-4">
          <li>Black water – mostly water from urine and faeces.</li>
          <li>Grey water – mostly water from laundry, kitchen, and bathroom</li>
          </ul>
          </Paragraph>

          <Subheader>
            Moving Bed Bio Reactor (MBBR):           
          </Subheader>
          <Paragraph>
          TIU MBBR technology provides for a complete treatment based on new generation aerobic
 media technology. The plant includes all unit processes and treatment components required
 to accomplish flow equalization, primary treatment, carbon removal and nitrification, solids
 removal (secondary settling) and disinfection (chlorination). 
 </Paragraph>
 <Paragraph>
The MBBR technology has the ability to be used on a small footprint as opposed to the fixed
 bio reactors larger footprint. This enables us to treat high volumes of sewage in containerized
 and movable units. The MBBR can also be built in underground concrete tanks.
 </Paragraph>
 <Paragraph>
 TIU owns Mobile Toilets (Mobilets) which are deployed for convenience purposes in all the
 camps and remote operational areas. Sewage from these Mobilets is collected on a regular
 basis using a Vacuum Truck and sent to the STP for proper treatment before disposal.
          </Paragraph>
          
        </ServicePage>
      </div>
    </>
  )
}

export default WasteWateTreatment